function date_time_cht(id)
{
        date = new Date;
        year = date.getFullYear();
        month = date.getMonth();
        months = new Array('1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月');
        d = date.getDate();
        day = date.getDay();
        days = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六');
        h = date.getHours();
        if(h<10)
        {
                h = "0"+h;
        }
        m = date.getMinutes();
        if(m<10)
        {
                m = "0"+m;
        }
        s = date.getSeconds();
        if(s<10)
        {
                s = "0"+s;
        }
        result = ''+days[day]+', '+months[month]+''+d+'日'+year+'年'+' - '+h+':'+m+':'+s;
        document.getElementById(id).innerHTML = result;
        setTimeout('date_time("'+id+'");','1000');
        return true;
}
